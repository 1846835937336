import React, { useEffect, useRef, useState } from "react";

const otpCss = {
  width: "50px",
  height: "50px",
  margin: "5px",
  padding: "5px",
  textAlign: "center",
  fontSize: "1.2em",
  borderRadius: "12px",
  border: "none",
  boxShadow: "1px 1px 3px"
};

const Otp = ({ length, onSubmit }) => {
  const inputRef = useRef([]);
  const [value, setValue] = useState(new Array(length).fill(""));

  const onChangeHandler = ({ target: { value: inputValue } }, index) => {
    if (isNaN(inputValue)) return;

    const newValue = [...value];
    newValue[index] = inputValue.slice(-1);
    setValue(newValue);

    if (inputValue && index < length - 1) inputRef.current[index + 1].focus();

    const finalValue = newValue.join("");
    finalValue.length === length && onSubmit(finalValue);
  };

  const onClickHandler = (index) => inputRef.current[index].setSelectionRange(1, 1);

  const onKeyDownHandler = (e, index) => {
    if (e.key === "Backspace" && index > 0 && !value[index]) {
      inputRef.current[index - 1].focus();
    }
  };

  const [time, setTime] = useState(150);
  useEffect(() => {
    if (inputRef.current[0]) inputRef.current[0].focus();
    let timer = setInterval(() => {
        setTime((time) => {
          if (time === 0) {
            clearInterval(timer);
            return 0;
          } else return time - 1;
        });
      }, 1000);
  }, []);


  return (
    <div>
      <h3 class="otpheading">Verify your account with us!</h3><br></br>
      <h4 class="otpsubheading">OTP has been send to</h4>
      <h4 class="sendto">par**********@gmail.com | 704*****69</h4>
      <form class="otpbox">
        {value.map((item, index) => (
          <input
            key={index}
            ref={(input) => (inputRef.current[index] = input)}
            value={item}
            style={otpCss}
            placeholder="0"
            onChange={(e) => onChangeHandler(e, index)}
            onClick={() => onClickHandler(index)}
            onKeyDown={(e) => onKeyDownHandler(e, index)}
          />
        ))}
      </form>
      <h4 class="resendotp"> Resend OTP in {`${Math.floor(time / 60)}`.padStart(2, 0)}:
      {`${time % 60}`.padStart(2, 0)}</h4>
      <h4 class="invalidotp">Invalid OTP!</h4>
    </div>
  );
};
export default Otp;