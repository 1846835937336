import React from 'react';
import { CiLink } from 'react-icons/ci';
import { IoIosApps } from "react-icons/io";
import { DiResponsive } from "react-icons/di";
import { MdOutlineSecurity, MdSupport } from "react-icons/md";
import { BiSolidLike } from "react-icons/bi";
import { Link } from "react-router-dom";

export default function Index() {
  return (
    <>
      <header id="menu-jk" class="container-fluid">
          <div class="container">
              <div class="row headerbox">
                   <div class="col-lg-3 col-md-12 logo">
                       <img class="white" src="../assets/images/logo.png" alt="Logo"/>

                       <a class="small-menu d-lg-none" data-toggle="collapse" data-target="#menu" href="#menu" >
                           <i  class="fas d-lg-none fa-bars"></i>
                      </a>
                   </div>
                   <div id="menu" class="col-lg-9 d-none d-lg-block navigation">
                       <ul>
                           <li><a href="#home">Home</a></li>
                           <li><a href="#features">Features</a></li>
                           <li><a href="#gallery">Gallery</a></li>
                           <li><a href="#contact">Contact Us</a></li>
                           <li class="loginbtn"><Link to="/login">Login</Link></li>
                       </ul>
                   </div>
              </div>
          </div>
      </header>

      
      <div class="slider container-fluid">
          <div class="container headertext">
              <div class="row">

                  <div data-aos="fade-left"  class="col-md-7 text-part" id="home">
                      <h1>Give your drops, save other's life</h1>
                      <p>We connect blood donation camp and Blood Donor. Come and Let's together save the 
                        Life of peoples who are suffering from getting blood. Join with us and help people 
                        to get new life. We hope that this platform will fulfill all your needs. So
                        what are you waiting for, come and let's bring your donation camp online with US.<br/>
                        
                        <br/>We are Coming Soon on &#128525;&#129395;
                         </p>

                       <div class="down-row">
                         <img src="assets/images/android.png" alt="Play Store"/>
                          <img src="assets/images/apple.png" alt="App Store"/>
                       </div>
                   </div>

                   <div data-aos="fade-right"  class="col-md-5 image-part">
                        <img src="assets/images/feature.png" class="himg" alt="Mobile"/>
                   </div>
              </div>
          </div>
      </div>

      <div>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4025199340012222"
        crossorigin="anonymous"></script>
        <ins class="adsbygoogle"
            style={{display:"block"}}
            data-ad-client="ca-pub-4025199340012222"
            data-ad-slot="9116523528"
            data-ad-format="auto"
            data-full-width-responsive="true">
        </ins>
        <script>
            (adsbygoogle = window.adsbygoogle || []).push({});
        </script>
      </div>
      
     <section id="features" class="features container-fluid">
         <div class="container">
            <div class="row session-title">
                <h2>Why To Choose Us ?</h2>
            </div>
             <div class="row feature-row">
                 <div class="col-md-4">
                     <div class="leftcard">
                         <CiLink className='reactIcon'/>
                         <h4 className='cmtxt'>Custom URL</h4>
                         <p className='cstxt'>We will Provide Custom URL.</p>
                     </div>
                 </div>
                 <div class="col-md-4">
                     <div class="rightcard">
                         <IoIosApps className='reactIcon'/>
                         <h4 className='cmtxt'>Custom App</h4>
                         <p className='cstxt'>We will provide custom App.</p>
                     </div>
                 </div>

                 <div class="col-md-4">
                     <div class="leftcard">
                         <DiResponsive className='reactIcon'/>
                         <h4 className='cmtxt'>Fully Responsive</h4>
                         <p className='cstxt'>Our website is fully Responsive.</p>
                     </div>
                 </div>

                 <div class="col-md-4">
                     <div class="leftcard">
                        <MdOutlineSecurity className='reactIcon'/>
                         <h4 className='cmtxt'>Security</h4>
                         <p className='cstxt'>We will use 256 Encryption.</p>
                     </div>
                 </div>

                 <div class="col-md-4">
                     <div class="rightcard">
                         <BiSolidLike className='reactIcon'/>
                         <h4 className='cmtxt'>Easy to Use</h4>
                         <p className='cstxt'>It can be easily use by any person.</p>
                     </div>
                 </div>

                 <div class="col-md-4">
                     <div class="leftcard">
                         <MdSupport className='reactIcon'/>
                         <h4 className='cmtxt'>Support</h4>
                         <p className='cstxt'>We will provide Exceelent support.</p>
                     </div>
                 </div>
             </div>
         </div>
     </section>


     <div class="feature-2">
       <div class="container">
           <div class="row">
             <div class="col-md-8 feat-detail">
                  <div class="fet-det-row row">
                      <div class="col-md-2 col-sm-3 col-4">
                          <div class="count-cov">
                            1
                          </div>
                      </div>
                      <div class="col-md-10 col-sm-9 col-8">
                        <h1>Register With Us</h1>
                        <p>Let's Register your Blood Camp with us and enjoy our functionality...</p>
                      </div>
                  </div>

                  <div class="fet-det-row row">
                      <div class="col-md-2 col-sm-3 col-4">
                          <div class="count-cov">
                            2
                          </div>
                      </div>
                      <div class="col-md-10 col-sm-9 col-8">
                        <h1>Bring your Camp Online</h1>
                        <p>Guy's Stop using offline process of blood camp. Now it's Right time to bring 
                            your Donation camp online. So What are you waiting For Let's get Started. 
                        </p>
                      </div>
                  </div>

                  <div class="fet-det-row row">
                      <div class="col-md-2 col-sm-3 col-4">
                          <div class="count-cov">
                            3
                          </div>
                      </div>
                      <div class="col-md-10 col-sm-9 col-8">
                        <h1>Get Custom URL & App</h1>
                        <p>We will Provide Custom URL for your Website and we also provide you
                            your custom app. Let's Enjoy this movement with us </p>
                      </div>
                  </div>
                  <br/>


             </div>
             <div class="col-md-4 feat-image">
               <img src="assets/images/intro.gif" className='feaImg' alt="Gif"/>
             </div>
           </div>
       </div>
     </div>

    <div>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4025199340012222"
        crossorigin="anonymous"></script>
        <ins class="adsbygoogle"
            style={{display:"block"}}
            data-ad-format="autorelaxed"
            data-ad-client="ca-pub-4025199340012222"
            data-ad-slot="4985706825">
        </ins>
        <script>
            (adsbygoogle = window.adsbygoogle || []).push({});
        </script>
    </div>

<section id="gallery" class="image-gallery">
  <div class="container">
    <div class="row session-title">
                   <h2>Our Admin Pannel</h2>
                   <p>We have Various Pannel . You can See some of our Admin panel screens in below Slider...</p>
               </div>
    <div class="row">
      <div id="owl-demo">

          <div class="item"><img src="assets/images/gallery/1.jpg" alt="Profile"/></div>
          <div class="item"><img src="assets/images/gallery/2.jpg" alt="Sidebar"/></div>
          <div class="item"><img src="assets/images/gallery/3.jpg" alt="Dashboard"/></div>
          <div class="item"><img src="assets/images/gallery/4.jpg" alt="Staff Member"/></div>
          <div class="item"><img src="assets/images/gallery/5.jpg" alt="Donor Registration"/></div>
          <div class="item"><img src="assets/images/gallery/6.jpg" alt="Success Donor Registration"/></div>
          <div class="item"><img src="assets/images/gallery/7.jpg" alt="Blood Bank Master"/></div>
          <div class="item"><img src="assets/images/gallery/8.jpg" alt="Gift"/></div>
        </div>
    </div>
  </div>
</section>

   <footer id="contact" class="container-fluid footerCard">
       <div class="container">

           <div class="row content-ro">
             <div class="col-lg-4 col-md-12 footer-form">
                <h2>About Jivandrops</h2>

                <p>We connect blood donation camp and Blood Donor. Come and Let's together save the 
                        Life of peoples who are suffering from getting blood. Join with us and help people 
                        to get new life. We hope that this platform will fulfill all your needs. So
                        what are you waiting for, come and let's bring your donation camp online with US.</p>
             </div>

               <div class="col-lg-4 col-md-12 footer-links">
                  <div class="row no-margin mt-2">
                   <h2>Quick Links</h2>
                   <ul>
                           <li><a href="/" className='footerlink'>Home</a></li>
                           <li><a href="#features" className='footerlink'>Features</a></li>
                           <li><a href="#gallery" className='footerlink'>Gallery</a></li>
                           <li><a href="#contact" className='footerlink'>Contact Us</a></li>
                   </ul>
                   </div>
                  

               </div>
               <div class="col-lg-4 col-md-12 footer-contact">
                   <h2>Contact Informatins</h2>
                   {/* <div class="address-row">
                       <div class="icon">
                           <i class="fas fa-map-marker-alt"></i>
                       </div>
                       <div class="detail">
                           <p>46-29 Indra Street, Southernbank, Tigaione, Toranto, 3006 Canada</p>
                       </div>
                   </div> */}
                   <div class="address-row">
                       <div class="icon">
                           <i class="far fa-envelope"></i>
                       </div>
                       <div class="detail">
                            <a href='mailto: jivandrops@gmail.com' className='footerlink'>jivandrops@gmail.com</a><br/>
                            <a href='mailto: parthspatel7023@gmail.com' className='footerlink'>parthspatel7023@gmail.com</a> 
                       </div>
                   </div>
                   <div class="address-row">
                       <div class="icon">
                           <i class="fas fa-phone"></i>
                       </div>
                       <div class="detail">
                           <a href="tel: +91 7046069169" className='footerlink'>+91 7046069169</a><br/> 
                           <a href="tel: +91 9737720609" className='footerlink'>+91 9737720609</a>
                       </div>
                   </div>
               </div>

           </div>
           <div class="footer-copy">
               <div class="row">
                   <div class="col-lg-8 col-md-6">
                       <p>Copyright &copy; jivandrops All right reserved.</p>
                   </div>
                   {/* <div class="col-lg-4 col-md-6 socila-link">
                       <ul>
                           <li><a><i class="fab fa-github"></i></a></li>
                           <li><a><i class="fab fa-google-plus-g"></i></a></li>
                           <li><a><i class="fab fa-pinterest-p"></i></a></li>
                           <li><a><i class="fab fa-twitter"></i></a></li>
                           <li><a><i class="fab fa-facebook-f"></i></a></li>
                       </ul>
                   </div> */}
               </div>
           </div>
       </div>
   </footer>


    </>
  )
}
