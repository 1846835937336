import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Index from './Pages/Index';
import Login from './Pages/Login';
import Reg from './Pages/Reg';
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index/>}></Route>
          <Route path="login" element={<Login/>}></Route>
          <Route path="reg" element={<Reg/>}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
