import React, { useEffect, useState } from 'react';
import { Form, ProgressBar } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Otp from '../Components/Otp';


const Reg = () => {

  const [step, setStep] = useState(1);
  
  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };


// below script is for adhar card

// const [value, setValue] = useState('');
//   function adharCard(value) {
//     var sanitizedValue = value
//        .replace(/[^\d]/g, '')
//        .slice(0, 12)
//        .replace(/(\d{4})/g, '$1 ')
//        .trim();
//     setValue(sanitizedValue);
//  }

// let [value,setValue] = useLocalStorage('name', '');

 const handleOtpSubmit = (otp) => {
  alert(`Submitted OTP: ${otp}`);
  // replace the alert with your authentication logic
};

// below code is used to store value in localstorage.
const [person_name, setPerson_name] = useState('');
const [email, setEmail] = useState('');
const [mobile, setMobile] = useState('');
const [psw, setPsw] = useState('');
const [statename, setStatename] = useState('');
const [district, setDistrict] = useState('');
const [taluka, setTaluka] = useState('');
const [village, setVillage] = useState('');


const win = window.localStorage;

const handleSubmit = (event) => {
  event.preventDefault();
  win.clear();
  setPerson_name('');
  setEmail('');
  setMobile('');
  setPsw('');
  setStatename('');
  setDistrict('');
  setTaluka('');
  setVillage('');
};

useEffect(()=>{
  if (win.getItem('person_name'))
    setPerson_name(win.getItem('person_name'));
  if (win.getItem('email'))
    setEmail(win.getItem('email'));
  if (win.getItem('mobile'))
    setMobile(win.getItem('mobile'));
  if (win.getItem('psw'))
    setPsw(win.getItem('psw'));
  if (win.getItem('state'))
    setStatename(win.getItem('state'));
  if (win.getItem('district'))
    setDistrict(win.getItem('district'));
  if (win.getItem('taluka'))
    setTaluka(win.getItem('taluka'));
  if (win.getItem('village'))
    setVillage(win.getItem('village'));
},[win]);

  return (
    <Form onSubmit={handleSubmit}>
      <ProgressBar now={(step / 4) * 100} bgcolor="orange" className='progressbar' />
      {step === 1 && (
        <div class="wrapper step">
                <center><img src="../assets/images/logo.png" alt="Logo" class="logologinreg"/></center>
            <form class="formfield">
                <div class="form-field d-flex align-items-center">
                    <span class="fa fa-user"></span>
                    <input 
                    type="text" 
                    name="person_name" 
                    id="person_name" 
                    placeholder="Write your Name..." 
                    onChange={(e)=>setPerson_name(e.target.value)}
                    value={person_name}/>
                </div>
                {/* <div>
                  <span id="nameerror"class="validation"></span>
                </div> */}
                {/*                 
                <div class="form-field d-flex align-items-center">
                    <span class="fa fa-address-card"></span>
                    <input type="text" name="aadhar" id="aadhar" value={value} onChange={e => adharCard(e.target.value)} placeholder="Enter Your Aadhar Card No."/>
                </div> */}
                <div class="form-field d-flex align-items-center">
                    <span class="fa fa-envelope"></span>
                    <input 
                    type="email" 
                    name="email" 
                    id="email" 
                    placeholder="Enter Your Email..."
                    onChange={(e)=>setEmail(e.target.value)}
                    value={email}/>
                </div>
                {/* <div>
                  <span id="emailerror"class="validation"></span>
                </div> */}
                <div class="form-field formline2 d-flex align-items-center">
                    <span class="fa fa-phone-square" style={{fontSize:"19px"}}></span>
                    <input 
                    type="text" 
                    name="mobile" 
                    id="mobile" 
                    placeholder="Enter Your Mobile No."
                    onChange={(e)=>setMobile(e.target.value)}
                    value={mobile}/>
                </div>
                {/* <div>
                  <span id="mobileerror"class="validation"></span>
                </div> */}
                <div class="form-field formline2 d-flex align-items-center">
                    <span class="fas fa-key"></span>
                    <input 
                    type="password" 
                    name="psw" 
                    id="psw" 
                    placeholder="Create Your Password."
                    onChange={(e)=>setPsw(e.target.value)}
                    value={psw}/>
                </div>
                {/* <div>
                  <span id="pswerror"class="validation"></span>
                </div> */}
                <div className="d-flexs">
                    {step > 1 && (
                    <button class="btnlogin mt-3 btncolour" onClick={handlePrevious}>Previous</button>
                    )}
                    {step < 4 ? (
                    <button class="btnlogin mt-3 btncolour" onClick={handleNext}>Next</button>
                    ) : (
                    <button class="btnlogin mt-3 btncolour"></button>
                    )}
                </div>
            </form>
            <div class="text-center regfooter">
                <span>Already a member ?</span><Link to="/login"> Log In</Link>
            </div>
        </div>
      )}
      {step === 2 && (
        <div class="wrapper step">
        <center><img src="../assets/images/logo.png" alt="Logo" class="logologinreg"/></center>
        <form class="formfield">
                <select 
                class="form-field d-flex align-items-center selectPadding"
                onChange={(e)=>setStatename(e.target.value)}
                value={statename}
                name="state">
                    <option>--: Select State :--</option>
                    <option> Gujarat </option> {/* Here Value is Dynamic*/}
                </select>
                {/* <span class="validationdd">This Field is Required.</span> */}
                <select class="form-field d-flex align-items-center selectPadding"
                onChange={(e)=>setDistrict(e.target.value)}
                value={district}
                name="district">
                    <option>--: Select District :--</option>
                    <option> Ahmedabad </option> {/* Here Value is Dynamic*/}
                </select>
                <select 
                class="form-field formline2 d-flex align-items-center selectPadding"
                onChange={(e)=>setTaluka(e.target.value)}
                value={taluka}
                name="taluka">
                    <option>--: Select Taluka :--</option>
                    <option> Mandal </option> {/* Here Value is Dynamic*/}
                </select>
                <select 
                class="form-field formline2 d-flex align-items-center selectPadding"
                onChange={(e)=>setVillage(e.target.value)}
                value={village}
                name="village">
                    <option>--: Select Village or Area :--</option>
                    <option> Chankya Park Society </option> {/* Here Value is Dynamic*/}
                </select><br/>
            <div className="d-flexs">
                {step > 1 && (
                <button class="btnlogin mt-3 btncolour regbtnpre" onClick={handlePrevious}>Previous</button>
                )}
                {step < 4 ? (
                <button class="btnlogin mt-3 btncolour regbtnnext" 
                onClick={() => { handleNext(); }} >Next</button>
                ) : (
                <button class="btnlogin mt-3 btncolour"></button>
                )}
            </div>
        </form>
    </div>
      )}
      {step === 3 && (
        <div class="wrapper step">
            <center><img src="../assets/images/logo.png" alt="Logo" class="logologinreg"/></center>
            <Otp length={6} onSubmit={handleOtpSubmit}/>
            <div className="d-flexs">
                    {step > 1 && (
                    <button class="btnlogin mt-4 btncolour regbtnpre" onClick={handlePrevious}>Previous</button>
                    )}
                    {step < 4 ? (
                    <button class="btnlogin mt-4 btncolour regbtnnext" onClick={handleNext}>Next</button>
                    ) : (
                    <button class="btnlogin mt-3 btncolour">Submit</button>
                    )}
                </div>
        </div>
        
      )}
      {step === 4 && (
        
        <div class="wrapper step chkmobile">
            <center><img src="../assets/images/logo.png" alt="Logo" class="logologinreg"/></center>
            <h5 class="payheading">Congratulation &#129395; your Member ID is 2458945 !</h5>
            <div class="checkoutbox">
                  <h5 class='paymentlabel'>Basic amount</h5>
                  <h5 class="paymentamount">350</h5>
                  <h5 class='maintenance'>Maintenance charge</h5>
                  <h5 class="maintenanceamt">15</h5>
                  <h5 class='payable'>Payable Amount</h5>
                  <h5 class="payableamt">365</h5>    
            </div>
            <div className="d-flexs">
                    {step < 4 ? (
                    <button class="btnlogin mt-4 btncolour regbtnpre" onClick={handleNext}>Next</button>
                    ) : (
                    <button class="btnlogin mt-3 btncolour paybtn">Pay &#8377;365</button>
                    )}
                </div>
        </div>
        
      )}
      
    </Form>
  );
};

export default Reg;