import React from 'react'
import { Link } from "react-router-dom";

export default function Login() {
  return (
    <>
      <div class="wrapper step">
            <center><img src="../assets/images/logo.png" alt="Logo" class="logologinreg"/></center>
        <form class="formfield">
            <div class="form-field d-flex align-items-center">
                <span class="far fa-id-badge"></span>
                <input type="text" name="id" id="id" placeholder="ID"/>
            </div>
            <div class="form-field d-flex align-items-center">
                <span class="far fa-user"></span>
                <input type="text" name="userName" id="userName" placeholder="Username"/>
            </div>
            <div class="form-field pass formline2 d-flex align-items-center">
                <span class="fas fa-key"></span>
                <input type="password" name="password" id="pwd" placeholder="Password"/>
            </div>
            <button class="btnlogin mt-3 btncolour">Login</button>
            <div class="text-center loginfooter">
                <a href="/">Forget password?</a>  <center><a href="/" class="notmember">Not a member?<Link to="/reg"> Sign up Now!</Link></a></center>
            </div>
        </form>
    </div>
    </>
  )
}
